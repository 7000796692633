/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useContext, useEffect, useRef } from 'react';
import {
    object, string, shape, bool,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import mbpLogger from 'mbp-logger';
import { getBaseCode } from '../../../../helpers/tracking/product/dataLayerProductHelpers';

import DesktopMobile from '../../../../helpers/DesktopMobile/DesktopMobile';
import { selectedSkuType } from './common/types';
import { CrossMerch } from '../../CrossMerchContainer/CrossMerchContainer';
import { getBrand } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import RatingsUI from '../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/RatingsUI';
import useIsPlaPage from '../../../../helpers/hooks/useIsPlaPage';
import useIsDisplayChannel from '../../../../helpers/hooks/useIsDisplayChannel';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = (theme) => ({
    productReviewContainer: {
        paddingBottom: '10px',
        margin: 0,
        [theme.breakpoints.down(767)]: {
            justifyContent: 'left',
            margin: '0 0 0 10px',
        },
    },
    alignment: {
        position: 'absolute',
        width: '178px',
        right: 0,
        bottom: 'calc(100% - 60px)',
        [theme.breakpoints.down(767)]: {
            display: 'none',
        },
        '@media (max-width: 1023px) and (min-width:768px)': {
            bottom: 'calc(100% - 25px)',
            position: 'relative',
        },
        '@media (max-width: 768px) and (min-width:600px)': {
            bottom: 'calc(100% - 60px)',
        },
        '@media (max-width: 601px)': {
            bottom: 'calc(100% - 60px)',
        },
    },
    productReviewFoodStandardProductContainer: {
        position: 'absolute',
        width: '178px',
        right: 0,
        bottom: 'calc(100% - 60px)',
        [theme.breakpoints.down(767)]: {
            display: 'none',
        },
    },

    showOnMobile: {
        [theme.breakpoints.down(767)]: {
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'relative',
            marginTop: '5px',
            '& >div': {
                flexBasis: 'auto',
            },
            '& .text-m': {
                marginLeft: '4px',
            },
        },
    },

    ratingShowOnMobile: {
        [theme.breakpoints.down(767)]: {
            display: 'flex',
            justifyContent: 'flex-start',
            position: 'relative',
            marginTop: '5px',
            '& >div': {
                flexBasis: 'auto',
            },
            '& .text-m': {
                marginLeft: '4px',
            },
        },
    },

    productName: {
        fontWeight: 400,
        fontSize: 20,
        marginTop: 0,
        marginBottom: 20,
    },

    deliveryMessage: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        boxSizing: 'border-box',
        color: theme.palette.cms?.clubAvailablility || '#404040',
        textAlign: 'center',
        margin: 0,
        lineHeight: 'normal',
    },

    ratingRaised: {
        padding: '16px 0px 10px 24px',
        [theme.breakpoints.down(900)]: {
            padding: '16px 0px 10px 24px;',
        },
        [theme.breakpoints.down(769)]: {
            padding: '8px 16px 0px 24px',
        },
        [theme.breakpoints.down(480)]: {
            display: 'inline-block',
            padding: '8px 8px 10px',
        },
    },
    ratingRaisedForcedRedesigned: {
        padding: '8px 24px 10px 0px',
    },
    foodRatingRaised: {
        padding: '8px 0px 10px',
        right: 0,
        width: '178px',
        bottom: 'calc(100% - 30px)',
        '& .pr-review-snippet-container': {
            width: 'max-content',
        },
        [theme.breakpoints.down(767)]: {
            padding: '0px',
        },
    },
    inline: {
        display: 'inline-block',
        float: 'left',
    },
    productReviewStyling: {
        top: 40,
    },
    review: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down(767)]: {
            display: 'none',
        },
    },
    showReview: {
        display: 'flex',
    },
    clubReview: {
        display: 'inline-flex',
        float: 'right',
        marginTop: 8,
    },
    plaProductReviewAlignment: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '2px 0px 8px',
        '@media (max-width: 1280px) and (min-width:600px)': {
            marginTop: '8px',
        },
        '@media (max-width:600px)': {
            marginTop: '18px',
            marginBottom: '0px',
        },
        '& .rating-star': {
            color: theme.palette.yotpoButton || '#4E9F82 !important',
        },
        '& .text-m': {
            color: `${theme.palette.cta4 }!important` || '#595959 !important',
            lineHeight: '18px',
            fontSize: '12px',
            marginLeft: '8px',
        },
    },
    productReviewClubContainer: {
        marginBottom: '1rem',
    },
    productReviewWrapper: {
        position: 'relative',
        bottom: 'unset',
        right: 'unset',
        width: 'auto',
        [theme.breakpoints.down(767)]: {
            display: 'block',
        },
    },
    productNameV2: {
        fontSize: '2rem',
        lineHeight: '2.5rem',
        fontWeight: 700,
        margin: '0px 0px 20px 0px',
    },

    alignLeft: {
        [theme.breakpoints.down(600)]: {
            justifyContent: 'flex-start',
        },
    },
    alignmentWrapper: {
        position: 'relative',
        maxWidth: 'max-content',
    },
    alignShowReview: {
        '& > div': {
            paddingRight: '24px',
            marginBottom: '6px',
        },
    },
    plaReview: {
        justifyContent: 'flex-start',
    },
    prRecommendations: {
        '& .pr-snippet-stars-reco-reco': {
            display: 'none',
        },
    },
});

const ProductReview = ({
    classes,
    product,
    selectedSku,
    productUrl,
    right,
    inline,
    isClubProduct,
    showUnderTitle,
    skuEnabledOnMobile,
    isStandardPdp,
    isPosition,
    isClubPDPV2,
    noRender,
    isPdpRedesignEnabled,
}) => {
    const { featureFlags = {}, presentation_family: presentationFamily = '' } = useContext(CrossMerch);
    // Removing this design from any feature and abtest
    const newUpsellDesign = presentationFamily === 'food';
    const isSmallerDevice = useMediaQuery('(max-width: 767px)');
    const isDisplayChannel = useIsDisplayChannel();
    const clickableDivRef = useRef(null);
    const dispatch = useDispatch();

    // Leaving it for now till I know how PowerReview is initialized
    useEffect(() => {
        if (typeof window !== 'undefined' && typeof window.yotpo !== 'undefined' && typeof window.yotpo.refreshWidgets === 'function') {
            try {
                // Call refresh only if yotpo initialized
                if (window.yotpo.initialized) {
                    window.yotpo.refreshWidgets();
                }
            } catch (exception) {
                mbpLogger.logError({ appName: process.env.npm_package_name, message: 'Unable to refresh the yotpo widget', jsError: exception });
            }
        }
    }, []);

    useEffect(() => {
        const handleClick = () => {
            if (typeof document !== 'undefined') {
                const avgScoreElement = document.querySelector?.('.avg-score');
                if (avgScoreElement) {
                    // Get the text content from the element
                    const avgScoreText = avgScoreElement.textContent;
                    dispatch(trackEvent(
                        {
                            eventCategory: 'Product Page',
                            eventAction: 'Review',
                            feature_detail: `${selectedSku.id} | ${avgScoreText}`,
                            feature_element: 'Review',
                            feature_category: 'Product Page',
                            eventName: 'feature_interaction',
                        },
                    ));
                }
            }
        };
        const observeDOM = () => {
            const observer = new MutationObserver(() => {
                if (clickableDivRef.current) {
                    const clickableDiv = clickableDivRef.current;
                    if (clickableDiv && !clickableDiv.hasListenerAttached) {
                        clickableDiv.addEventListener('click', handleClick);
                        clickableDiv.hasListenerAttached = true; // Flag to indicate the listener is attached
                        observer.disconnect();
                    }
                }
            });

            observer.observe(document.body, { childList: true, subtree: true });
        };

        if (typeof document !== 'undefined') {
            observeDOM();
        }

        // Cleanup
        return () => {
            const clickableDiv = clickableDivRef.current;
            if (clickableDiv && clickableDiv.hasListenerAttached) {
                clickableDiv.removeEventListener('click', handleClick);
                clickableDiv.hasListenerAttached = false; // Reset the flag
            }
        };
    }, []);

    const brand = useSelector(getBrand);
    // leaving it for now till I get a confirmation that I can delete ff for yotpo once PR is good
    const isYotpoFromAggregator = brand.id  !== selectedSku.brandId && featureFlags['is-yotpo-stars-from-aggregator'];

    const partNumber = getBaseCode(product?.partNumber);
    const productReviewStyling = (!newUpsellDesign && selectedSku?.prices?.[0]?.value > selectedSku?.prices?.[1]?.value) ? classes.productReviewStyling : '';
    const foodPlaRedesign = featureFlags['which-food-pla-design'];
    let productReviewAdjustment = newUpsellDesign ? `${isPdpRedesignEnabled ? classes.alignmentWrapper : classes.alignment}` : classes.productReviewFoodStandardProductContainer;

    if ((useIsPlaPage() || isDisplayChannel) && foodPlaRedesign === 'three_column' && noRender) {
        return <></>;
    }
    // it is used to set the position relative
    if (isPosition) {
        productReviewAdjustment = classes.productReviewWrapper;
    }
    const isAvailabilityAtcLeftAlign = featureFlags['Reviewis-availability-atc-left-aligned'];
    if (showUnderTitle) {
        productReviewAdjustment = `${classes.showOnMobile} ${isAvailabilityAtcLeftAlign ? classes.alignLeft : ''} ${presentationFamily === 'food' ? classes.ratingShowOnMobile : ''}`;
    }
    if (featureFlags['is-simplified-pdp'] && useIsPlaPage() && foodPlaRedesign === 'variant') {
        productReviewAdjustment = `${classes.plaProductReviewAlignment} ${classes.alignment}`;
    }
    const productReviewFromAggregatorCss = showUnderTitle || (presentationFamily === 'food' && skuEnabledOnMobile) ? `${classes.showReview} ${isPdpRedesignEnabled ? classes.alignShowReview : ''} ${ isAvailabilityAtcLeftAlign ? classes.alignLeft : ''}` : '';
    if (!partNumber) return null;
    const loadProductReview = () => (
        <Grid item sm={12} lg={12}>
            <div
                id="pr-reviewsnippet"
                className={`${featureFlags['is-force-pdp-redesign-enabled'] ? classes.ratingRaised : classes.ratingRaisedForcedRedesigned } ${right ? classes.pullRight : ''} ${inline ? classes.inline : ''} ${classes.prRecommendations} ${presentationFamily === 'food' ? classes.foodRatingRaised : '' }`}
            />
        </Grid>
    );

    // to avoid duplicate render
    // yotpo for new design and for food will render from different file
    if (newUpsellDesign && product?.productSkus?.length <= 1 && isSmallerDevice  && isStandardPdp) {
        return <></>;
    }

    return (
        <>
            { isYotpoFromAggregator
            && (
                <div className={`${classes.review} ${ productReviewFromAggregatorCss}`}>
                    <RatingsUI reviews={product.reviews} isPdpRedesignEnabled={isPdpRedesignEnabled} />
                </div>
            )}

            <>
                {product?.productType?.toLowerCase() === 'club'  && (
                    <>
                        <DesktopMobile
                            breakpoint={600}
                            desktop={() => (
                                <>
                                    <h1 className={isClubPDPV2 ? classes.productNameV2 : classes.productName}>
                                        {ReactHtmlParser(selectedSku?.name)}
                                    </h1>
                                    <p className={classes.deliveryMessage}>{selectedSku?.availability?.deliveryMessage || ''}</p>
                                    { !isYotpoFromAggregator && <div className={classes.productReviewClubContainer}>{loadProductReview()}</div>}
                                </>
                            )}
                            mobile={() => (
                                <>
                                    {!isYotpoFromAggregator && (
                                        <div className={isClubProduct ? classes.clubReview : ''}>
                                            { loadProductReview()}
                                        </div>
                                    )}
                                </>
                            )}
                        />

                    </>
                )}
                {
                    (product?.productType?.toLowerCase() === 'regular_product' || 'collection')
                            && product?.productType?.toLowerCase() !== 'club'
                            && presentationFamily === 'food'
                            && featureFlags['is-reviews-show-non-curated']
                            && !isYotpoFromAggregator
                            && (
                                <Grid container className={`${featureFlags['is-pdp-v-2-enabled'] ? `${productReviewAdjustment} ${productReviewStyling}` : classes.productReviewContainer}`}>
                                    {loadProductReview()}
                                </Grid>
                            )
                }
                {
                    presentationFamily === 'flower'
                        && featureFlags['is-reviews-show-non-curated']
                        && !isYotpoFromAggregator
                            && (
                                <Grid container className={classes.productReviewContainer}>
                                    {loadProductReview()}
                                </Grid>
                            )
                }
            </>
        </>
    );
};

ProductReview.propTypes = {
    classes: object.isRequired,
    product: object.isRequired,
    selectedSku: selectedSkuType,
    productUrl: string.isRequired,
    right: bool,
    inline: bool,
    isClubProduct: bool,
    showUnderTitle: bool,
    skuEnabledOnMobile: bool,
    isStandardPdp: bool,
    isPosition: bool,
    isClubPDPV2: bool,
    noRender: bool,
    isPdpRedesignEnabled: bool,
};

ProductReview.defaultProps = {
    right: false,
    inline: false,
    selectedSku: {},
    isClubProduct: false,
    showUnderTitle: false,
    skuEnabledOnMobile: false,
    isStandardPdp: false,
    isPosition: false,
    isClubPDPV2: false,
    noRender: true,
    isPdpRedesignEnabled: false,
};

const enhance = compose(
    withStyles(styles),
);

export default enhance(ProductReview);
